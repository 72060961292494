
// Do not edit directly
// Generated on Thu, 16 Jan 2025 17:20:26 GMT

$mainNavigationColorTextDefault: #00244a;
$mainNavigationColorTextNotActive: #00244AB3;
$mainNavigationColorLogoDefault: #ffffff;
$mainNavigationColorLogoHoverFocus: #cad7e3;
$mainNavigationColorIconDefault: #ffffff;
$mainNavigationColorIconHoverFocus: #cad7e3;
$mainNavigationColorBorderDefault: #ffffff;
$mainNavigationColorBackgroundNavigation: #ffffff;
$mainNavigationColorBackgroundTop: linear-gradient(90deg, #00244A 50%, #005CA9 100%);
$mainNavigationColorBackgroundOverlay: #00325E66;
$mainNavigationColorTabActive: #ffffff;
$mainNavigationColorTabOther: rgba(255,255,255,0.7);
$mainNavigationColorTabBackground: #00244a;
$mainNavigationColorTabHoverFocus: #70D1F4;
$mainNavigationFontButton: 600 14px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$mainNavigationFontItem: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$mainNavigationFontTabDesktopOther: 400 14px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$mainNavigationFontTabDesktopActive: 700 14px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$mainNavigationFontTabTabletMobileOther: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$mainNavigationFontTabTabletMobileActive: 700 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$languageSelectorFontItem: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$languageSelectorColorBackground: #00335d;
$languageSelectorColorText: #ffffff;
$languageSelectorColorBorder: #ffffff;
$languageSelectorColorSelectedIcon: #00244a;
$languageSelectorColorSelectedBackground: #ffffff;
$dropdownColorBackgroundPrimary: #00335d;
$dropdownColorBackgroundImage: #004178;
$dropdownColorTextDefault: #ffffff;
$dropdownColorTextHover: #f0f1f2;
$dropdownColorBorder: #cad7e3;
$dropdownFontDesktopTitle: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$dropdownFontDesktopItem: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$dropdownFontMobileTitle: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$dropdownFontMobileItem: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
