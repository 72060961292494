
// Do not edit directly
// Generated on Thu, 16 Jan 2025 17:20:26 GMT

$titleFontDesktopH1: 400 44px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontDesktopH2: 100 44px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontDesktopH3: 100 36px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontDesktopH4: 100 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontDesktopH5: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontDesktopH6: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontMobileH1: 400 36px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontMobileH2: 100 36px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontMobileH3: 100 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontMobileH4: 100 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontMobileH5: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleFontMobileH6: 500 18px/1.5 Roboto, Arial, Helvetica, sans-serif;
$titleColorDefault: #00244a;
$titleColorReversed: #ffffff;
