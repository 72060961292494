
// Do not edit directly
// Generated on Thu, 16 Jan 2025 17:20:26 GMT

$colorBorderPrimary: #66747f; // Default border
$colorBorderReversed: #ffffff;
$colorBorderStrong: #00244a;
$colorBorderModerate: #004178;
$colorBorderBrand: #005ca9;
$colorBorderSubtle: #cad7e3;
$colorBorderClear: #edf5fa;
$colorBorderMinimal: #f0f1f2;
$colorBorderError: #DD0000;
$colorBorderOutline: #43a27f;
$colorBorderOutlineSecondary: #f1fbf6;
$colorSurfacePrimary: #ffffff; // Default layer color
$colorSurfaceSecondary: #f9f9f9; // Color for themable background
$colorSurfaceBrand: #005ca9;
$colorSurfacePale: #f7fafd;
$colorSurfaceBright: #f3f8fc;
$colorSurfaceClear: #edf5fa;
$colorSurfaceSubtle: #cad7e3;
$colorSurfaceModerate: #004178;
$colorSurfaceBold: #00335d;
$colorSurfaceStrong: #00244a;
$colorSurfaceTransparentBrand: #005ca9cc;
$colorSurfaceTransparentModerate: #00325E66;
$colorSurfaceTransparentSubtle: #005ca91a;
$colorSurfaceTransparentPale: #cad7e399;
$colorSurfaceTransparentMinimal: #f0f1f21a;
$colorSurfaceTransparentFull: #ffffff00;
$colorSurfaceGradientPrimary: linear-gradient(90deg, #00244A 50%, #005CA9 100%);
$colorSurfaceGradientReverseRight: linear-gradient(90deg, #FFFFFF00 00 0%, #FFFFFF 100%);
$colorSurfaceGradientReverseLeft: linear-gradient(90deg, #FFFFFF 00 0%, #FFFFFF00 100%);
$colorSurfaceGradientSecondary: linear-gradient(90deg, #00244ACC 50%, #005CA9CC 100%);
$colorSurfaceAccentYoutube: #f00;
$colorSurfaceAccentVimeo: #17d5ff;
$colorTextBrand: #005ca9;
$colorTextStrong: #00244a;
$colorTextRunningText: #5f666d;
$colorTextMinimal: #f0f1f2;
$colorTextReversed: #ffffff;
$colorTextFadedDark: #5f666dB3;
$colorTextFadedLight: rgba(255,255,255,0.7);
$colorTextAccentDiamond: #70D1F4;
$colorTextFadedDarker: #00244AB3;
$colorTextError: #DD0000;
$colorIconPrimary: #00244a;
$colorIconModerate: #004178;
$colorIconSecondary: #005ca9;
$colorIconSubtle: #cad7e3;
$colorIconReversed: #ffffff;
$colorIconAccentDiamond: #70D1F4;
$colorIconAccentGold: #FFD700;
$colorFadeBlue500A80: #005ca9cc;
$colorFadeDarkBlue500A70: #00244AB3;
$colorFadeBlue500A40: #00325E66;
$colorFadeBlue500A10: #005ca91a;
$colorFadeLightestBlue600A60: #cad7e399;
$colorFadeGrey400A90: rgba(95,102,109,0.9);
$colorFadeLivid50A10: #f0f1f21a;
$colorFadeWhite10A70: rgba(255,255,255,0.7);
$colorFadeWhite10A0: #ffffff00;
$fontSecondaryMedium14: 500 14px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontSecondaryMedium16: 500 16px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontSecondaryMedium18: 500 18px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontSecondaryMedium24: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontSecondaryMedium28: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontSecondaryThin24: 100 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontSecondaryThin28: 100 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontSecondaryThin36: 100 36px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontSecondaryThin44: 100 44px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontSecondaryRegular36: 400 36px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontSecondaryRegular44: 400 44px/1.5 Roboto, Arial, Helvetica, sans-serif;
$fontPrimaryRegular14: 400 14px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimaryRegular16: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimaryRegular18: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimaryRegular24: 400 24px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimaryRegular28: 400 28px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimaryBold14: 700 14px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimaryBold16: 700 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimaryBold18: 700 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimaryBoldUppercase16: 700 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimaryBoldUppercase18: 700 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimarySemiBold14: 600 14px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimarySemiBold16: 600 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$fontPrimarySemiBold18: 600 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$underBlue40a: 0 2px 8px 0 #00244a66;
$sideRightBlue40a: 4px 0 8px 0 #00244a66;
$underWhite: 0 4px 8px 0 #ffffff33;
$centerBlue10a: 0 0 8px 0 #00244a1a;
$centerWhite20a: 0 0 8px 0 #ffffff33;
$centerBlue40a: 0 0 8px 0 #00244a66;
$sideLeftBlue40a: -4px 0 8px 0 #00244a66;
$topBlue20a: 0 -4px 8px 0 #00244a33;
$centerBlue15a: 0 0 8px 0 #00244a26;
