
// Do not edit directly
// Generated on Thu, 16 Jan 2025 17:20:26 GMT

$stepperColorBackgroundFinished: #00244a;
$stepperColorBackgroundCurrent: #005ca9;
$stepperColorBackgroundFutur: #ffffff;
$stepperColorInfoFinished: #ffffff;
$stepperColorInfoCurrent: #ffffff;
$stepperColorInfoFutur: #00244a;
$stepperColorSeperator: #005ca9;
$stepperFontInfo: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
