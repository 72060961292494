
// Do not edit directly
// Generated on Thu, 16 Jan 2025 17:20:26 GMT

$footerColorBackground: #00244a;
$footerColorTextDefault: #ffffff;
$footerColorTextDescription: #f0f1f2;
$footerColorTextHover: #f0f1f2;
$footerColorIconDefault: #ffffff;
$footerColorIconHover: #cad7e3;
$footerColorLogoDefault: #ffffff;
$footerColorLogoHover: #cad7e3;
$footerFontDefault: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$footerFontBold: 700 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$footerFontSubTitle: 500 18px/1.5 Roboto, Arial, Helvetica, sans-serif;
$footerFontDesktopTitle: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$footerFontMobileTitle: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
