
// Do not edit directly
// Generated on Thu, 16 Jan 2025 17:20:26 GMT

$contactColorBackground: #ffffff;
$contactColorBorder: #f0f1f2;
$contactColorIcon: #00244a;
$contactColorTextRegion: #005ca9;
$contactColorTextName: #00244a;
$contactColorTextLinks: #005ca9;
$contactColorTextDescription: #5f666d;
$contactFontDesktopName: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$contactFontDesktopDescription: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$contactFontDesktopMeta: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$contactFontMobileName: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$contactFontMobileDescription: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$contactFontMobileMeta: 500 18px/1.5 Roboto, Arial, Helvetica, sans-serif;
